import type { NuxtConfig } from '@nuxt/schema';
import { VividSSRStyleUrl } from '../constants';
import { getEnvironmentVariable } from './runtimeConfig';

const BifrostAdobeDtmLaunchUrl = getEnvironmentVariable('BIFROST_ADOBE_DTM_LAUNCH_URL');

export const AppTitle = 'API Developer';

const app: NuxtConfig['app'] = {
  head: {
    charset: 'UTF-8',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
      { name: 'robots', content: getEnvironmentVariable('BIFROST_X_ROBOTS_TAG') },
      { name: 'algoliaEnv', content: getEnvironmentVariable('BIFROST_ALGOLIA_ENV') },
      { name: 'algoliaAppId', content: getEnvironmentVariable('BIFROST_ALGOLIA_APP_ID') },
      { name: 'algoliaSearchApiKey', content: getEnvironmentVariable('BIFROST_ALGOLIA_SEARCH_API_KEY') },
      { name: 'google-site-verification', content: getEnvironmentVariable('BIFROST_GOOGLE_SITE_VERIFICATION') },
      { name: 'zd-site-verification', content: 'uvm1dmhxbq8aajjxr8xsb7' },
    ],
    title: AppTitle,
    bodyAttrs: {
      class: 'vvd-root',
    },
    link: [
      // vivid needs to be imported before the vendor CSS so that the variables
      // are correctly applied
      {
        rel: 'stylesheet',
        type: 'text/css',
        href: VividSSRStyleUrl,
      },
      { rel: 'stylesheet', type: 'text/css', href: '/vendor.css' },
      { rel: 'icon', type: 'image/svg+xml', href: '/favicon.ico' },
    ],
    script: [...[...(BifrostAdobeDtmLaunchUrl ? [{ src: BifrostAdobeDtmLaunchUrl, async: true }] : [])]],
  },
};

export default app;
