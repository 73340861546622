<template>
  <div class="overlay">
    <div class="spinner">
      <Spinner />
      <p class="text">
        <slot>{{ $t('components.loader.message') }}</slot>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue';
import { useBrandingStore } from '@/store/branding';

const branding = useBrandingStore();

const Spinner = defineAsyncComponent(
  () =>
    import(process.client && branding.data?.vendorId === 'vonage' ? './VonageSpinner.vue' : './CircleDotSpinner.vue')
);
</script>

<style lang="scss" scoped>
.overlay {
  height: 100%;
}
.overlay,
.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text {
  margin: 30px;
  font-weight: 200;
}
</style>
