function convertRemToPixels(rem: number) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

// eslint-disable-next-line import/prefer-default-export
export const getHeaderHeightInPx = (): number => {
  // Get the header height from the CSS variable. However, this value will be given in REM, e.g. '3.4375rem'
  const headerHeightRem = getComputedStyle(document.documentElement).getPropertyValue('--bifrost-header-height');

  // if header height is not provided, default to 0
  if (!headerHeightRem) return 0;

  // Therefore we need to convert the value to pixels
  const rem = parseFloat(headerHeightRem.replace('rem', ''));
  return convertRemToPixels(rem);
};
