import { createError } from 'h3';
import { useRuntimeConfig } from 'nuxt/app';

export default function redirectFromVisualEditor(path: string) {
  // this should only work in draft mode
  if (useRuntimeConfig().public.BifrostStoryblokVersion !== 'draft') {
    throw createError({
      statusCode: 404,
      statusMessage: 'Page Not Found',
    });
  }
  return { path };
}
