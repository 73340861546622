import { watch } from 'vue';
import { replaceLocaleRootForUrl, saveLocaleToStorage } from '@/utils/i18n';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export function useLocaleChangeDetection() {
  const i18n = useI18n();
  const router = useRouter();
  const location = useRoute();

  watch(
    () => i18n.locale.value,
    async (locale, currentLocale) => {
      saveLocaleToStorage(locale);
      // if current route has different locale, update route
      const routeLocale = location.matched[0]?.meta.locale;
      if (routeLocale && routeLocale !== locale) {
        await router.push(replaceLocaleRootForUrl(location.fullPath, currentLocale, locale));
      }
    }
  );
}
