import { useRuntimeConfig } from 'nuxt/app';
import { useRoute } from 'vue-router';
import Bugsnag from '@bugsnag/js';

export function getCanonicalUrlFromWindowLocation() {
  // return current href without query args or hash
  try {
    const route = useRoute();
    return new URL(route.path, useRuntimeConfig().public.BifrostDevPortal).href;
  } catch (error) {
    Bugsnag.notify(error);
  }
  return '';
}

export function setCanonicalUrl(url: string) {
  let tag = document.querySelector(`link[rel="canonical"]`);
  if (!tag) {
    tag = document.createElement('link');
    tag.setAttribute('rel', 'canonical');
    document.head.appendChild(tag);
  }
  tag.setAttribute('href', url);
  return tag;
}
