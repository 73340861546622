import { useFetch } from 'nuxt/app';
import { IsProduction } from '@/constants';
import type { ISbResult, ISbStoriesParams } from '@storyblok/vue';

type UseFetchOptions<T> = Parameters<typeof useFetch<T>>[1];
type UseFetchDataType<T> = ReturnType<typeof useFetch<T>>['data']['value'];
type MethodOptions<T> = Omit<UseFetchOptions<T>, 'method'>;

async function fetch<T>(url: string, options: UseFetchOptions<T> = {}): Promise<UseFetchDataType<T>> {
  const { data, error } = await useFetch<T>(url, options);
  if (error.value) {
    throw error.value;
  }
  return data.value;
}

const get = <T extends NonNullable<unknown>>(url: string, options: MethodOptions<T> = {}) =>
  // @ts-ignore
  fetch<T>(url, { method: 'GET', ...options });

const post = <T extends NonNullable<unknown>>(url: string, options: MethodOptions<T> = {}) =>
  // @ts-ignore
  fetch<T>(url, { method: 'POST', ...options });

export const getUserInfo = () => get<API.UserInfo>(IsProduction ? '/api/v1/portal/user' : '/api/v1/devtools/user');
export const logout = () => get<string>('/api/v1/user/logout');

export const isUserRegisteredForEvent = async (eventId: string) =>
  JSON.parse(await get<string>(`/api/v1/developer/events/register/${eventId}`)) as boolean;

export const registerUserForEvent = (eventId: string) => post(`/api/v1/developer/events/register/${eventId}`);

export const getShikiHighlight = (id: string, code: string, lang: string) =>
  post<string>(`/api/v1/portal/shiki?id=${id}`, {
    body: { code, lang },
  });

export interface SbResult<T = unknown> extends ISbResult {
  data: T;
}

export type SbStoryResult<T = unknown> = SbResult<{ story: T }>;

export type SbStoriesResult<T = unknown> = SbResult<{ stories: T[] }>;

export const getStory = async <T = unknown>(path: string, body: ISbStoriesParams): Promise<SbStoryResult<T>> => {
  return post<SbStoryResult<T>>(`/api/v1/portal/storyblok/story/${path}`, { body });
};

export const getStories = async <T = unknown>(body: ISbStoriesParams): Promise<SbStoriesResult<T>> => {
  return post<SbStoriesResult<T>>(`/api/v1/portal/storyblok/stories`, { body });
};

export const getStoryWithFallback = async <T = unknown>(
  language: string,
  basePath: string,
  storyPath: string,
  params: ISbStoriesParams
): Promise<SbStoryResult<T>> => {
  return post<SbResult<T>>(`/api/v1/portal/storyblok/story/fallback`, {
    body: {
      language,
      basePath,
      storyPath,
      ...params,
    },
  });
};
