import { start as singleSpaStart } from 'single-spa';
import { defineNuxtPlugin } from 'nuxt/app';
import analytics from '@/utils/analytics/analytics';
import prerender from '@/utils/prerender';
import { setCustomComponentPrefix } from '@vonage/vivid-vue';

export default defineNuxtPlugin(() => {
  // override default behavior to avoid defining the
  // same component twice
  window.customElements.define = (
    define => (name, constructor, options) =>
      customElements.get(name) || define.call(window.customElements, name, constructor, options)
  )(window.customElements.define);

  // tell prerender we are going to notify it when the page is ready
  window.prerenderReady = false;

  // set analytics trigger for page load
  window.analytics = {
    onPageLoad: () => analytics.trackPageView(window.location.pathname),
  };

  singleSpaStart({ urlRerouteOnly: true });

  setCustomComponentPrefix('portal');

  // record entrypoint for prerender
  prerender?.recordEntrypoint();
});
