import { type LocaleMessages, type VueMessageType } from 'vue-i18n';
import enUS from '../lang/en-US.json';
import zhCn from '../lang/zh-CN.json';
import jaJp from '../lang/ja-JP.json';

const StorageKey = 'i18n.locale';
export interface SupportedLocale {
  locale: string;
  lang: string;
  pattern: RegExp;
  messages: LocaleMessages<VueMessageType>;
}

export const supportedLocales: SupportedLocales = [
  {
    locale: 'en',
    lang: 'en-US',
    pattern: /^(en|en-.+)$/, // catchall for en-* & en
    // @ts-ignore
    messages: enUS,
  },
  {
    locale: 'cn',
    lang: 'zh-CN',
    pattern: /^(zh|zh-.+)$/, // catchall for zh-* & zh
    // @ts-ignore
    messages: zhCn,
  },
  {
    locale: 'ja',
    lang: 'ja-JP',
    pattern: /^(ja|ja-.+)$/, // catchall for ja-* & ja
    // @ts-ignore
    messages: jaJp,
  },
];

export type SupportedLocales = SupportedLocale[];
export const loadLocaleFromStorage = () => {
  try {
    return window.localStorage.getItem(StorageKey);
  } catch (e) {
    // Ignore SecurityError in Safari when in private mode
    return undefined;
  }
};

export const saveLocaleToStorage = (locale: string) => {
  try {
    window.localStorage.setItem(StorageKey, locale);
  } catch (e) {
    // Ignore QuotaExceededError or SecurityError in Safari when in private mode
  }
};

export function getPreferredSupportedLocale(
  languages: readonly string[],
  // eslint-disable-next-line @typescript-eslint/no-shadow
  supportedLocales: SupportedLocales
): string | null {
  for (const language of languages) {
    for (const { pattern, locale } of supportedLocales) {
      if (pattern.test(language)) return locale;
    }
  }
  return null;
}

export const replaceLocaleRootForUrl = (path: string, current: string, target: string) =>
  path.replace(new RegExp(`^\\/${current}`), `/${target}`);
