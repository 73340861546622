import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBlogArticleCTACard, LazyCodeTabs, LazyGitHubCodeSnippet, LazyAuthBlokToggle, LazyBanner, LazyEventSection, LazyAnnouncementBanner, LazyHeaderSignUpCard, LazyImageRowSection, LazyLandingPageHeader, LazySlackBanner, LazyVonageCommunityEventShowCaseSection, LazyVonageCommunityHeaderSection, LazyVonageCommunityKeywordsBannerSection, LazyVonageCommunityNeedSupportSection, LazyVonageCommunityPartnersSection, LazyVonageCommunityResourcesSection, LazyVonageCommunitySpotlightSection, LazyVonageCommunityStayUpToDateSection, LazyVonageCommunityWelcomeVonageSection, LazyVonageDeveloperStarlightsSection, LazyVonageViewpointSection, LazyEventCard, LazyEventGridSearch, LazyEventTag, LazyEventsGridSection, LazyEventsJoinCommunitySlackSection, LazyNoDataFoundPlaceholder, LazyCobrandedHomePageHeader, LazyCobrandedHomePageUseCasesSection, LazyVonageHomePageBlogSection, LazyVonageHomePageCallToActionSection, LazyVonageHomePageCommunityStatsSection, LazyVonageHomePageCustomerLogosSection, LazyVonageHomePageHeaderSection, LazyVonageHomePageJoinCommunitySection, LazyVonageHomePageLanguagePickerSection, LazyVonageHomePageProductsSection, LazyVonageHomePageProjectsSection, LazyBlogArticle, LazyBlogAuthor, LazyCommunityPage, LazyEventsPage, LazyCobrandedHomePage, LazyVonageHomePage, LazyResolveRelations } from '#components'
const lazyGlobalComponents = [
  ["BlogArticleCTACard", LazyBlogArticleCTACard],
["CodeTabs", LazyCodeTabs],
["GitHubCodeSnippet", LazyGitHubCodeSnippet],
["AuthBlokToggle", LazyAuthBlokToggle],
["Banner", LazyBanner],
["EventSection", LazyEventSection],
["AnnouncementBanner", LazyAnnouncementBanner],
["HeaderSignUpCard", LazyHeaderSignUpCard],
["ImageRowSection", LazyImageRowSection],
["LandingPageHeader", LazyLandingPageHeader],
["SlackBanner", LazySlackBanner],
["VonageCommunityEventShowCaseSection", LazyVonageCommunityEventShowCaseSection],
["VonageCommunityHeaderSection", LazyVonageCommunityHeaderSection],
["VonageCommunityKeywordsBannerSection", LazyVonageCommunityKeywordsBannerSection],
["VonageCommunityNeedSupportSection", LazyVonageCommunityNeedSupportSection],
["VonageCommunityPartnersSection", LazyVonageCommunityPartnersSection],
["VonageCommunityResourcesSection", LazyVonageCommunityResourcesSection],
["VonageCommunitySpotlightSection", LazyVonageCommunitySpotlightSection],
["VonageCommunityStayUpToDateSection", LazyVonageCommunityStayUpToDateSection],
["VonageCommunityWelcomeVonageSection", LazyVonageCommunityWelcomeVonageSection],
["VonageDeveloperStarlightsSection", LazyVonageDeveloperStarlightsSection],
["VonageViewpointSection", LazyVonageViewpointSection],
["EventCard", LazyEventCard],
["EventGridSearch", LazyEventGridSearch],
["EventTag", LazyEventTag],
["EventsGridSection", LazyEventsGridSection],
["EventsJoinCommunitySlackSection", LazyEventsJoinCommunitySlackSection],
["NoDataFoundPlaceholder", LazyNoDataFoundPlaceholder],
["CobrandedHomePageHeader", LazyCobrandedHomePageHeader],
["CobrandedHomePageUseCasesSection", LazyCobrandedHomePageUseCasesSection],
["VonageHomePageBlogSection", LazyVonageHomePageBlogSection],
["VonageHomePageCallToActionSection", LazyVonageHomePageCallToActionSection],
["VonageHomePageCommunityStatsSection", LazyVonageHomePageCommunityStatsSection],
["VonageHomePageCustomerLogosSection", LazyVonageHomePageCustomerLogosSection],
["VonageHomePageHeaderSection", LazyVonageHomePageHeaderSection],
["VonageHomePageJoinCommunitySection", LazyVonageHomePageJoinCommunitySection],
["VonageHomePageLanguagePickerSection", LazyVonageHomePageLanguagePickerSection],
["VonageHomePageProductsSection", LazyVonageHomePageProductsSection],
["VonageHomePageProjectsSection", LazyVonageHomePageProjectsSection],
["BlogArticle", LazyBlogArticle],
["BlogAuthor", LazyBlogAuthor],
["CommunityPage", LazyCommunityPage],
["EventsPage", LazyEventsPage],
["CobrandedHomePage", LazyCobrandedHomePage],
["VonageHomePage", LazyVonageHomePage],
["ResolveRelations", LazyResolveRelations],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
