<template>
  <!-- Use key=0 to avoid re-rendering the App component -->
  <App key="0">
    <NuxtLayout>
      <div class="developer-portal">
        <component :is="ErrorPage" />
      </div>
    </NuxtLayout>
  </App>
</template>

<script setup lang="ts">
import App from '@/components/App.vue';
import { defineAsyncComponent } from 'vue';
import type { NuxtError } from 'nuxt/app';

const props = defineProps({
  error: Object as () => NuxtError,
});

const ErrorPage = defineAsyncComponent(() => {
  switch (props.error?.statusCode) {
    case 404:
      return import('@/components/errors/NotFoundErrorPage.vue');

    default:
      return import('@/components/errors/UnexpectedErrorPage.vue');
  }
});
</script>
