import sha256 from 'crypto-js/sha256';
import { type ProfileInfo } from '@/utils/analytics/ProfileInfo';
import type { AdobeDigitalData } from '@/types/adobeAnalytics';
import { useBrandingStore } from '@/store/branding';

const PRIMARY_CATEGORY = 'biz:api:adp:developer';

const digitalDataTemplate: AdobeDigitalData = {
  page: {
    pageInfo: {
      pageName: '',
      functionDept: '',
      primaryCategory: PRIMARY_CATEGORY,
      siteIdentifier: '',
      lob: '',
      subCategory1: '',
      subCategory2: '',
      subCategory3: '',
      appVersion: '',
      language: '',
      country: '',
      pageCategory: '',
      flowName: '',
      tokenFLag: '',
      spaFlag: '',
    },
    content: {
      author: '',
      businessSize: '',
      category: '',
      company: '',
      industry: '',
      name: '',
      product: '',
      profRole: '',
      publishDate: '',
      subCategory: '',
      topic: '',
      type: '',
    },
  },
  eventData: {
    events: '',
    fCTA: '',
    fIndustry: '',
    fLines: '',
    fName: '',
    interactionType: '',
    linesSlider: '',
  },
  user: {
    profile: {
      profileInfo: {
        visitorType: '',
        accountNumber: '',
        accountStatus: '',
        userType: '',
        userName: '',
        accountPlan: '',
        userId: '',
        responsiveSiteVersion: '',
        country: '',
        acctnumLocations: '',
        acctcontractFlag: '',
        email: '',
      },
    },
  },
};

const initialiseDataLayerIfNeeded = () => {
  if (!window.digitalData) {
    window.digitalData = digitalDataTemplate;
  }
};

const updatePageInfo = (dataLayer: AdobeDigitalData, pathArray: string[]) => {
  const { pageInfo } = dataLayer.page;
  const categoryArray = PRIMARY_CATEGORY.split(':');
  pageInfo.functionDept = categoryArray.slice(0, categoryArray.length - 1).join(':');
  pageInfo.siteIdentifier = categoryArray.slice(1, categoryArray.length).join(':');
  // eslint-disable-next-line prefer-destructuring
  pageInfo.lob = categoryArray[0];

  if (pathArray[0] === 'home') {
    pageInfo.pageName = `${PRIMARY_CATEGORY}:homepage`;
  } else {
    pageInfo.pageName = `${PRIMARY_CATEGORY}:${pathArray.join(':')}`;
  }
};

const updateSubCategories = (dataLayer: AdobeDigitalData, pathArray: string[]) => {
  const { pageInfo } = dataLayer.page;
  if (pathArray[0] === 'home') {
    const category = `${PRIMARY_CATEGORY}:homepage`;
    pageInfo.subCategory1 = category;
    pageInfo.subCategory2 = category;
    pageInfo.subCategory3 = category;
  } else {
    let subCategory1 = '';
    let subCategory2 = '';
    let subCategory3 = '';
    if (pathArray.length === 0) {
      subCategory1 = PRIMARY_CATEGORY;
      subCategory2 = PRIMARY_CATEGORY;
      subCategory3 = PRIMARY_CATEGORY;
    } else {
      subCategory1 = `${PRIMARY_CATEGORY}:${pathArray[0]}`;
      subCategory2 = subCategory1;
      subCategory3 = subCategory1;
      if (pathArray[1]) {
        subCategory2 = `${subCategory2}:${pathArray[1]}`;
        subCategory3 = subCategory2;
      }
      if (pathArray[2]) {
        subCategory3 = `${subCategory3}:${pathArray[2]}`;
      }
    }
    pageInfo.subCategory1 = subCategory1;
    pageInfo.subCategory2 = subCategory2;
    pageInfo.subCategory3 = subCategory3;
  }
};

const updateProfileInfo = (dataLayer: AdobeDigitalData, profileInfo: ProfileInfo) => {
  if (profileInfo.status === 'logged-in') {
    dataLayer.user.profile.profileInfo.loggedinStatus = 'logged in';
    dataLayer.user.profile.profileInfo.visitorType = 'customer';
    dataLayer.user.profile.profileInfo.userId = String(profileInfo.nexmoUserId);
    dataLayer.user.profile.profileInfo.userName = sha256(profileInfo.email).toString();
    dataLayer.user.profile.profileInfo.email = sha256(profileInfo.email).toString();
    dataLayer.user.profile.profileInfo.accountNumber = profileInfo.selectedApiKey;
  } else {
    dataLayer.user.profile.profileInfo.loggedinStatus = 'non-logged in';
    dataLayer.user.profile.profileInfo.visitorType = '';
    dataLayer.user.profile.profileInfo.userId = '';
    dataLayer.user.profile.profileInfo.userName = '';
    dataLayer.user.profile.profileInfo.email = '';
    dataLayer.user.profile.profileInfo.accountNumber = '';
  }
};

const updateBrandingInfo = (dataLayer: AdobeDigitalData) => {
  const brandingStore = useBrandingStore();
  if (brandingStore && brandingStore.data) {
    dataLayer.page.pageInfo.siteIdentifier = brandingStore.data.vendorId;
  }
};

const updatePageEventInteractionType = (dataLayer: AdobeDigitalData) => {
  if (dataLayer.eventData) {
    dataLayer.eventData.interactionType = '';
  }
};

const updateDigitalData = (path: string, profileInfo: ProfileInfo) => {
  initialiseDataLayerIfNeeded();
  const { digitalData } = window;

  const pathArray = path.split('/').filter(e => e);
  pathArray.shift(); // remove locale

  updatePageInfo(digitalData, pathArray);
  updateSubCategories(digitalData, pathArray);
  updateProfileInfo(digitalData, profileInfo);
  updateBrandingInfo(digitalData);
  updatePageEventInteractionType(digitalData);
};

export const trackAdobePageView = (path: string, profileInfo: ProfileInfo) => {
  // Create or update the digitalData object (data layer) for the new page
  updateDigitalData(path, profileInfo);

  /*
   * Fire the tracking call for the new page.
   * "_satellite.track fires all rules using the Direct Call event type that has been configured with the given
   * identifier from the Core tag extension."
   */
  window._satellite?.track('allpages');
};

export const updatePreferredLanguagesInfo = (languages: string[]) => {
  if (window && window.digitalData) {
    window.digitalData.user.profile.profileInfo.languages = languages.join(':');
  }
};

export const updateInteractionType = (interactionType: string) => {
  if (window && window.digitalData && window.digitalData.eventData) {
    window.digitalData.eventData.interactionType = interactionType;
  }
};

export const trackEngagementRule = () => {
  if (window && window._satellite) window._satellite?.track('ticker-engagement-rule');
};
