import { supportedLocales } from '@/utils/i18n';
import { getMfeRoutes } from '@/utils/mfe';
import { defaultLocale } from '@/i18n.config';
import type { RouteRecordRaw } from 'vue-router';

export default <RouteRecordRaw[]>[
  ...supportedLocales.map(({ locale }) => ({
    path: `/${locale}`,
    meta: { locale },
    component: () => import('@/components/mfe/MfeView.vue'),
    children: [
      { path: '/', redirect: `/${locale}/home` },
      { path: `/${locale}`, redirect: `/${locale}/home` },

      // dynamic MFE routes
      ...getMfeRoutes(locale),
    ],
  })),
  // redirect to landing page for /
  {
    path: '',
    redirect: () => `/${defaultLocale}/home`,
  },
  // created APIDP-1065 to move this to /[locale]/search
  {
    path: '/search',
    component: () => import('@/components/SearchPage.vue'),
    meta: { locale: defaultLocale },
  },
  {
    path: '/',
    redirect: () => `/${defaultLocale}/home`,
  },
  // debugger for configs in storyblok
  ...supportedLocales.map(({ locale }) => ({
    path: `/${locale}/config/:id(.*)`,
    component: () => import('@/components/pages/storyblok/StoryblokPage.vue'),
    props: ({ params: { id } }) => ({ storyblokPath: `config/${id}` }),
    meta: {
      layout: false,
      locale,
      // only show this page for draft instances (local & QA)
      validate: () => useRuntimeConfig().public.BifrostStoryblokVersion === 'draft',
    },
  })),
  // redirect all paths missing the lang to correct locale
  {
    path: '/:catchAll(.*)',
    redirect: ({ path }) => `/${defaultLocale}${path}`,
  },
];
