import {
  getPreferredSupportedLocale,
  loadLocaleFromStorage,
  saveLocaleToStorage,
  supportedLocales,
} from './utils/i18n';

export function getNavigatorLanguages(navigator: Navigator): readonly string[] {
  if (navigator && navigator.languages) {
    return navigator.languages;
  }
  if (navigator && navigator.language) {
    return [navigator.language];
  }
  // Handle the case where navigator is not defined or doesn't have the expected properties
  return [];
}

export const defaultLocale = 'en';

export const getLocaleMessages = () =>
  Object.fromEntries(supportedLocales.map(({ locale, messages }) => [locale, messages]));

const getLocale = () => {
  let locale = loadLocaleFromStorage();
  // not stored in the browser, let's figure out which one we need
  if (!locale) {
    // get from browser config or just use default

    locale = getPreferredSupportedLocale(getNavigatorLanguages(window.navigator), supportedLocales) ?? defaultLocale;
    // save it for future use
    saveLocaleToStorage(locale);
  }
  return locale;
};

export default defineI18nConfig(() => ({
  legacy: false,
  locale: process.server ? defaultLocale : getLocale(),
  fallbackLocale: defaultLocale,
  messages: getLocaleMessages(),
  silentTranslationWarn: true, // Suppress warnings when translation not found
  warnHtmlMessage: false, // Suppress warnings when message is not string
}));
