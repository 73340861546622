export const appHead = {"meta":[{"charset":"UTF-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0"},{"name":"robots","content":"$BIFROST_X_ROBOTS_TAG"},{"name":"algoliaEnv","content":"$BIFROST_ALGOLIA_ENV"},{"name":"algoliaAppId","content":"$BIFROST_ALGOLIA_APP_ID"},{"name":"algoliaSearchApiKey","content":"$BIFROST_ALGOLIA_SEARCH_API_KEY"},{"name":"google-site-verification","content":"$BIFROST_GOOGLE_SITE_VERIFICATION"},{"name":"zd-site-verification","content":"uvm1dmhxbq8aajjxr8xsb7"}],"link":[{"rel":"stylesheet","type":"text/css","href":"https://d226lax1qjow5r.cloudfront.net/assets/vivid-ssr/3.51.0/vivid-ssr.min.css"},{"rel":"stylesheet","type":"text/css","href":"/vendor.css"},{"rel":"icon","type":"image/svg+xml","href":"/favicon.ico"}],"style":[],"script":[{"src":"$BIFROST_ADOBE_DTM_LAUNCH_URL","async":true}],"noscript":[],"charset":"UTF-8","title":"API Developer","bodyAttrs":{"class":"vvd-root"}}

export const appAppTitle = "API Developer"

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000