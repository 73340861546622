import { useRoute } from 'vue-router';
import { watch } from 'vue';

export default function fixWeirdNestedRouterBug() {
  const location = useRoute();

  watch(
    () => location.fullPath,
    () => {
      if (!window.history.state.current) {
        window.history.state.current = location.fullPath;
      }
    }
  );
}
