import { ref } from 'vue';
import { defineStore } from 'pinia';
import { loadFromLocalStorage, saveToLocalStorage } from '@/utils/localStorage';

const StorageKey = 'preferredApiKey';

/**
 * Stores the preferred API key. As currently the api key cannot be changed in the UI, this is only used to
 * remember the last api key we received when coming the dashboard.
 */
export const usePreferredApiKeyStore = defineStore('preferredApiKey', () => {
  const preferredApiKey = ref<string | null>(loadFromLocalStorage(StorageKey));

  const setPreferredApiKey = (apiKey: string) => {
    preferredApiKey.value = apiKey;
    saveToLocalStorage(StorageKey, apiKey);
  };

  return { preferredApiKey, setPreferredApiKey };
});
