import { ScrollTopPadding } from '@/constants';
import { getHeaderHeightInPx } from '@/utils/headerHeight';
import type { RouteLocationNormalized } from 'vue-router';

function determineMarginFromPageTop() {
  return getHeaderHeightInPx() + ScrollTopPadding;
}
export function scrollToElement(element: HTMLElement, behavior: ScrollBehavior = 'smooth') {
  window.scrollTo({
    left: 0,
    top: element.getBoundingClientRect().top - determineMarginFromPageTop() + window.scrollY,
    behavior,
  });
}

export function applyScrollForRoute(route: RouteLocationNormalized, behavior: ScrollBehavior = 'smooth') {
  if (route.hash) {
    const element = document.getElementById(decodeURIComponent(route.hash.substring(1)));
    if (element) return scrollToElement(element, behavior);
  }
  return window.scrollTo({ top: 0, behavior });
}
