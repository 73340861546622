import { usePreferredApiKeyStore } from '@/store/preferredApiKey';
import type { ProfileInfo } from '@/utils/analytics/ProfileInfo';
import analytics from '@/utils/analytics/analytics';
import { useUserStore } from '@/store/user';

export function setProfileInfoForAnalytics() {
  const preferredApiKeyStore = usePreferredApiKeyStore();
  const userStore = useUserStore();

  if (!userStore.info) {
    throw new Error('The preferred API key store needs to be initialised after the user store.');
  }

  let profileInfo: ProfileInfo = { status: 'logged-out' };
  if (userStore.info.status === 'logged-in') {
    const preferredKey = userStore.info.apiKeys.find(apiKey => apiKey.apiKey === preferredApiKeyStore.preferredApiKey);
    const firstMasterKey = userStore.info.apiKeys.find(apiKey => apiKey.master);
    const firstKey = userStore.info.apiKeys[0];
    const selectedKey = preferredKey ?? firstMasterKey ?? firstKey;

    profileInfo = {
      status: 'logged-in',
      nexmoUserId: userStore.info.nexmoUserId,
      email: userStore.info.email,
      selectedApiKey: selectedKey.apiKey,
    };
  }

  analytics.setProfileInfo(profileInfo);
}
