import type { RouteRecordRaw } from 'vue-router';
import { supportedLocales } from '@/utils/i18n';
import useOrgId from '@/utils/useOrgId';
import { VonageOrgID } from '@/constants';
import type { ISbStoryData } from '@storyblok/vue';
import redirectFromVisualEditor from '@/utils/storyblok/redirectFromVisualEditor';

const onlyVonageOrg = () => useOrgId().value === VonageOrgID;

const internalRoutes: RouteRecordRaw[] = [
  {
    path: '/home',
    component: () => import('@/components/pages/storyblok/StoryblokPage.vue'),
    props: { storyblokPath: 'home', brandSpecificPage: true },
  },
  {
    path: '/blog',
    component: () => import('@/components/pages/blog/BlogLandingPage.vue'),
  },
  // redirect to allow visual editor to work
  {
    path: '/branding/:orgId/:page',
    redirect: ({ params: { page }, meta: { locale } }) => redirectFromVisualEditor(`/${locale}/${page}`),
  },
  {
    path: '/branding/:orgId/events/:page',
    redirect: ({ params: { page }, meta: { locale } }) => redirectFromVisualEditor(`/${locale}/${page}`),
  },
  {
    path: '/branding/:orgId/blog/articles/:lang/:title',
    redirect: ({ params: { title, lang } }) => redirectFromVisualEditor(`/${lang}/blog/${title}`),
  },
  {
    path: '/branding/:orgId/blog/authors/:lang/:author',
    redirect: ({ params: { author, lang } }) => redirectFromVisualEditor(`/${lang}/blog/authors/${author}`),
  },
  {
    path: '/blog/:title',
    component: () => import('@/components/pages/storyblok/StoryblokPageWithFallback.vue'),
    props: ({ params: { title: storyblokStoryPath } }) => ({
      storyblokBasePath: 'blog/articles',
      storyblokStoryPath,
      additionalProperties: <T>(story: ISbStoryData<T>) => ({
        // we add this prop for older articles where the published date is not managed by the CMS
        updated: story.first_published_at || story.published_at || story.created_at,
      }),
    }),
  },
  {
    path: '/blog/authors/:author',
    component: () => import('@/components/pages/storyblok/StoryblokPageWithFallback.vue'),
    props: ({ params: { author: storyblokStoryPath } }) => ({
      storyblokBasePath: 'blog/authors',
      storyblokStoryPath,
      brandSpecificPage: false,
    }),
  },
  {
    path: '/community',
    component: () => import('@/components/pages/storyblok/StoryblokPage.vue'),
    props: { storyblokPath: 'community', brandSpecificPage: true },
  },
  {
    path: '/events',
    component: () => import('@/components/pages/storyblok/StoryblokPage.vue'),
    props: { storyblokPath: 'events/events', brandSpecificPage: true },
  },
  {
    path: '/events/register/:eventId',
    component: () => import('@/components/pages/event-registration/EventRegistrationPage.vue'),
    props: ({ params: { eventId } }: { params: { eventId: string } }) => ({ eventId }),
    meta: { title: 'Event Registration', validate: onlyVonageOrg },
  },
];

function transformInternalRouteIntoGlobal(routes: RouteRecordRaw[]): RouteRecordRaw[] {
  return routes.reduce((current, { path, meta = {}, ...rest }) => {
    return [
      ...current,
      ...supportedLocales.map(
        ({ locale }) =>
          ({
            path: `/${locale}${path}`,
            meta: { ...meta, locale },
            ...rest,
          }) as RouteRecordRaw
      ),
    ];
  }, [] as RouteRecordRaw[]);
}

export default transformInternalRouteIntoGlobal(internalRoutes);
