import { RouteRecordRaw } from 'vue-router';
import MfeYamlConfig from '../mfe.yml';

interface App {
  id: string;
  entrypoint: string;
  hotjarObscureText: boolean;
}

interface Route {
  path: string;
  app: string;
  router_base: string;
}

interface Config {
  apps: App[];
  routes: Route[];
}

const MfeConfig = MfeYamlConfig as Config;

const AppEntrypointMap = Object.fromEntries(MfeConfig.apps.map(({ id, entrypoint }) => [id, entrypoint]));

const HotjarObscureMap = Object.fromEntries(
  MfeConfig.apps.map(({ id, hotjarObscureText }) => [id, Boolean(hotjarObscureText)])
);

export function getMfeRoutes(locale: string): RouteRecordRaw[] {
  return MfeConfig.routes.map(({ path, app, router_base: staticRouterBase }) => {
    // we need to add the locale to the app id so we can refresh the application
    const id = `${locale}-${app}`;
    // add locale to router base
    const routerBase = `/${locale}${staticRouterBase}`;
    return {
      path,
      // empty component: the actual MFE rendering is handled by MfeView.vue
      component: { render: () => null },
      meta: {
        id,
        // disable tracking the page view as this is done inside the MFE
        analytics: { trackPageView: false },
        mfe: true,
      },
      props: () => ({
        id: `${locale}-${app}`,
        entrypoint: AppEntrypointMap[app],
        hotjarObscureText: HotjarObscureMap[app],
        locale,
        routerBase,
      }),
    };
  });
}
