<template>
  <slot></slot>
</template>

<script setup lang="ts">
import { type BrandingStoreData, useBrandingStore } from '@/store/branding';
import { onBeforeMount } from 'vue';
import { initialiseUserStore } from '@/utils/bootstrap/user';
import { initialiseBrandingStore } from '@/utils/bootstrap/branding';
import { setProfileInfoForAnalytics } from '@/utils/bootstrap/analytics';
import { useLocaleChangeDetection } from '@/utils/bootstrap/i18n';
import fixWeirdNestedRouterBug from '@/utils/bootstrap/bugfix';
import { useHead } from '@unhead/vue';
import { AppTitle } from '@/config/app';
import useOrgId from '@/utils/useOrgId';

const brandingStore = useBrandingStore();

onBeforeMount(() => {
  // put branding data onto the window object
  // for MFE apps to use
  window.branding = brandingStore.data as BrandingStoreData;
});

const orgId = useOrgId();

// initialise stores
await initialiseUserStore();
await initialiseBrandingStore(orgId.value);

// update document title with
useHead({
  title: `${brandingStore.data?.vendorName} ${AppTitle}`,
});

// setup user information for analytics
setProfileInfoForAnalytics();

// setup locale change detection
useLocaleChangeDetection();

// a strange bug that causes the history to lose the current state
fixWeirdNestedRouterBug();
</script>

<style lang="scss">
@import '@/styles/main';
</style>
