export function loadFromLocalStorage<T>(key: string): T | null {
  try {
    const stored = window.localStorage.getItem(key);
    if (stored !== null) {
      return JSON.parse(stored);
    }
    return null;
  } catch (e) {
    // Ignore SecurityError in Safari when in private mode
    return null;
  }
}

export function saveToLocalStorage<T>(key: string, value: T) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // Ignore QuotaExceededError or SecurityError in Safari when in private mode
  }
}
