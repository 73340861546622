type MetaWidthAttributes = Record<string, string>;
type MetaMap = Record<string, MetaWidthAttributes>;

function addMetaTagWithName(attributeName: string, attributeValue: string) {
  const tag = document.createElement('meta');
  tag.setAttribute(attributeName, attributeValue);
  document.head.appendChild(tag);
  return tag;
}

function getMetaTagWithName(attributeName: string, attributeValue: string) {
  let tag = document.querySelector(`meta[${attributeName}="${attributeValue}"]`);
  if (!tag) {
    tag = addMetaTagWithName(attributeName, attributeValue);
  }
  return tag;
}

// eslint-disable-next-line import/prefer-default-export
export function updateMetaTags(metaMap: MetaMap) {
  Object.entries(metaMap).forEach(([name, attributes]) => {
    const tag = getMetaTagWithName('name', name);
    Object.entries(attributes).forEach(([attribute, value]) => tag.setAttribute(attribute, value));
  });
}

export function getRobotsTag(brandingStoreVendorId: string, brand?: string) {
  return brandingStoreVendorId !== (brand || 'vonage') ? 'noindex' : 'all';
}
