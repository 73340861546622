import { type BrandingStoreData, useBrandingStore } from '@/store/branding';
import type { BrandingDataStory } from '@/types/storyblok';
import { getStory } from '@/api';

async function getBrandingData(orgId: string): Promise<BrandingStoreData> {
  const {
    data: { story },
  } = await getStory<BrandingDataStory>(`branding/${orgId}/config/branding`);
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention,unused-imports/no-unused-vars
    content: { _uid, component, ...branding },
  } = story;
  return branding as BrandingStoreData;
}

export async function initialiseBrandingStore(orgId: string) {
  const brandingStore = useBrandingStore();

  // get data from storyblok
  brandingStore.setData(await getBrandingData(orgId));
}
