import { updateMetaTags } from '@/utils/meta';

class PrerenderRouteManager {
  private initialRoute: string | null = null;

  private didRedirectToExternalPage = false;

  public recordEntrypoint() {
    const url = new URL(window.location.href);
    this.initialRoute = url.pathname;
  }

  public detectRedirect() {
    if (this.didRedirectToExternalPage) {
      return;
    }

    const url = new URL(window.location.href);
    if (this.initialRoute !== url.pathname) {
      // we have been redirected by the vue router
      // add meta tags for prerender
      updateMetaTags({
        'prerender-status-code': { content: '301' },
        'prerender-header': { content: `Location: ${window.location.href}` },
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private setResponseStatusCode(statusCode: string) {
    // remove all prerender tags
    document.querySelectorAll('meta[name^="prerender"]').forEach(tag => document.head.removeChild(tag));
    // set status tag
    updateMetaTags({ 'prerender-status-code': { content: statusCode } });
  }

  public redirectToExternalPage(url: string) {
    this.didRedirectToExternalPage = true;
    updateMetaTags({
      'prerender-status-code': { content: '301' },
      'prerender-header': { content: `Location: ${url}` },
    });
    this.pageHasFinishedRendering();
  }

  // eslint-disable-next-line class-methods-use-this
  public pageHasFinishedRendering() {
    window.prerenderReady = true;
  }

  public pageNotFound() {
    this.setResponseStatusCode('404');
  }

  public internalServerError() {
    this.setResponseStatusCode('500');
  }
}

// check browser agent for prerender
const isPrerender = /HeadlessChrome/.test(window.navigator.userAgent);
const prerender = isPrerender ? new PrerenderRouteManager() : null;

// set prerender on window object to share it with MFEs
declare global {
  interface Window {
    prerender: typeof prerender;
  }
}
window.prerender = prerender;

export default prerender;
