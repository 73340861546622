<template>
  <!-- Use key=0 to avoid re-rendering the App component -->
  <App key="0">
    <NuxtLayout>
      <Loader v-if="isLoading" />
      <NuxtPage v-else />
    </NuxtLayout>
  </App>
</template>

<script setup lang="ts">
import App from '@/components/App.vue';
import Loader from '@/components/loader/Loader.vue';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { debounce } from 'lodash-es';

const isLoading = ref(false);

const createDebounce = (value: boolean) =>
  debounce(
    () => {
      isLoading.value = value;
    },
    200,
    { trailing: true }
  );

const startLoading = createDebounce(true);
const stopLoading = createDebounce(false);

const router = useRouter();
router.beforeEach(async (to, from) => {
  if (to.path !== from.path && !to.meta.mfe) {
    startLoading();
  }
});
router.afterEach(stopLoading);
</script>
