import { defineStore } from 'pinia';
import { ref, type Ref } from 'vue';

export const useUserStore = defineStore('user', () => {
  const info: Ref<API.UserInfo | undefined> = ref();

  const setInfo = (value: API.UserInfo) => {
    info.value = value;
  };

  return { info, setInfo };
});
