import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/text-to-speech-phone-call-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/text-to-speech-phone-call-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/text-to-speech-phone-call-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/make-outbound-text-speech-phone-call-node-js-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/make-outbound-text-speech-phone-call-node-js-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/make-outbound-text-speech-phone-call-node-js-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/how-to-send-sms-messages-with-node-js-and-express-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/how-to-send-sms-messages-with-node-js-and-express-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/how-to-send-sms-messages-with-node-js-and-express-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/how-to-send-and-receive-sms-messages-with-node-js-and-express-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/how-to-send-and-receive-sms-messages-with-node-js-and-express-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/how-to-send-and-receive-sms-messages-with-node-js-and-express-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/send-sms-messages-python-flask-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/send-sms-messages-python-flask-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/send-sms-messages-python-flask-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/send-sms-messages-asp-net-mvc-framework-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/send-sms-messages-asp-net-mvc-framework-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/send-sms-messages-asp-net-mvc-framework-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/whats-different-now",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/whats-different-now",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/whats-different-now",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/what-is-voicexml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/what-is-voicexml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/what-is-voicexml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/memories-of-confoo",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/memories-of-confoo",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/memories-of-confoo",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/how-to-build-sms-2fa-that-wont-scare-away-your-users",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/how-to-build-sms-2fa-that-wont-scare-away-your-users",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/how-to-build-sms-2fa-that-wont-scare-away-your-users",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/gdc-2015-user-acquisition-analytics-and-a-whole-lot-of-bacon",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/gdc-2015-user-acquisition-analytics-and-a-whole-lot-of-bacon",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/gdc-2015-user-acquisition-analytics-and-a-whole-lot-of-bacon",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-at-the-capital-one-hackathon",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-at-the-capital-one-hackathon",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-at-the-capital-one-hackathon",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/announcing-the-nexmo-numbers-crm-edition-contest-winner",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/announcing-the-nexmo-numbers-crm-edition-contest-winner",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/announcing-the-nexmo-numbers-crm-edition-contest-winner",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/announcing-the-nexmo-numbers-cms-edition-contest-winner",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/announcing-the-nexmo-numbers-cms-edition-contest-winner",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/announcing-the-nexmo-numbers-cms-edition-contest-winner",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-disrupting-nyc",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-disrupting-nyc",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-disrupting-nyc",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-women-who-code-host-meetup-for-females-in-tech",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-women-who-code-host-meetup-for-females-in-tech",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-women-who-code-host-meetup-for-females-in-tech",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/building-a-phone-powered-photo-booth-with-nexmos-voice-and-sms-apis",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/building-a-phone-powered-photo-booth-with-nexmos-voice-and-sms-apis",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/building-a-phone-powered-photo-booth-with-nexmos-voice-and-sms-apis",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/build-rest-implementation-scales-better-smpp",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/build-rest-implementation-scales-better-smpp",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/build-rest-implementation-scales-better-smpp",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/developer-relations-at-nexmo",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/developer-relations-at-nexmo",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/developer-relations-at-nexmo",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/ruby-gem",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/ruby-gem",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/ruby-gem",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/real-time-web-apps-net-options",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/real-time-web-apps-net-options",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/real-time-web-apps-net-options",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/phptek-2016-livestream",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/phptek-2016-livestream",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/phptek-2016-livestream",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/official-php-client-lib",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/official-php-client-lib",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/official-php-client-lib",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/developer-new-nexmo-python-library-released-pycon",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/developer-new-nexmo-python-library-released-pycon",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/developer-new-nexmo-python-library-released-pycon",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/claim-nexmo-credit-help-disrupt-travel-industry-skyscanner",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/claim-nexmo-credit-help-disrupt-travel-industry-skyscanner",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/claim-nexmo-credit-help-disrupt-travel-industry-skyscanner",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/two-factor-authentication-2fa-ruby-rails-devise-nexmo-verify-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/two-factor-authentication-2fa-ruby-rails-devise-nexmo-verify-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/two-factor-authentication-2fa-ruby-rails-devise-nexmo-verify-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/talking-real-time-web-apps-net-at-ndc-oslo",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/talking-real-time-web-apps-net-at-ndc-oslo",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/talking-real-time-web-apps-net-at-ndc-oslo",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/let-users-login-phone-number-using-nexmos-verify-php-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/let-users-login-phone-number-using-nexmos-verify-php-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/let-users-login-phone-number-using-nexmos-verify-php-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/introducing-the-nexmo-command-line-interface-cli",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/introducing-the-nexmo-command-line-interface-cli",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/introducing-the-nexmo-command-line-interface-cli",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/win-tickets-electromagnetic-field-2016",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/win-tickets-electromagnetic-field-2016",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/win-tickets-electromagnetic-field-2016",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-to-present-keynote-developer-workshop-panel-at-all-about-the-api",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-to-present-keynote-developer-workshop-panel-at-all-about-the-api",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-to-present-keynote-developer-workshop-panel-at-all-about-the-api",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-laracon-laravel-5-3-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-laracon-laravel-5-3-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-laracon-laravel-5-3-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-tilda-emf-camp-badge",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-tilda-emf-camp-badge",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-tilda-emf-camp-badge",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/tomomi-joining-developer-relations-team",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/tomomi-joining-developer-relations-team",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/tomomi-joining-developer-relations-team",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-elephpants-at-zendcon",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-elephpants-at-zendcon",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-elephpants-at-zendcon",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/live-streaming-phpworld-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/live-streaming-phpworld-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/live-streaming-phpworld-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/olia-joining-the-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/olia-joining-the-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/olia-joining-the-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-voice-api-demo-telephony-ai-platforms-websockets",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-voice-api-demo-telephony-ai-platforms-websockets",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-voice-api-demo-telephony-ai-platforms-websockets",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/welcome-newest-developer-advocate-nexmo-devrel-team-chris-guzman-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/welcome-newest-developer-advocate-nexmo-devrel-team-chris-guzman-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/welcome-newest-developer-advocate-nexmo-devrel-team-chris-guzman-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/webinar-getting-started-nexmo-voice-websockets-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/webinar-getting-started-nexmo-voice-websockets-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/webinar-getting-started-nexmo-voice-websockets-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/big-changes-for-nexmos-java-client-library-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/big-changes-for-nexmos-java-client-library-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/big-changes-for-nexmos-java-client-library-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-ride-hailing-chatbot",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-ride-hailing-chatbot",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-ride-hailing-chatbot",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-aws-lex-connector-in-public-beta-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-aws-lex-connector-in-public-beta-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-aws-lex-connector-in-public-beta-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/ibm-bluemix-nexmo-api-platform-available",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/ibm-bluemix-nexmo-api-platform-available",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/ibm-bluemix-nexmo-api-platform-available",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/minting-new-javascript-developer-advocate-alex-lakatos-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/minting-new-javascript-developer-advocate-alex-lakatos-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/minting-new-javascript-developer-advocate-alex-lakatos-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/welcoming-bibi-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/welcoming-bibi-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/welcoming-bibi-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-latest-devrel-aaron-bassett-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-latest-devrel-aaron-bassett-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-latest-devrel-aaron-bassett-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/amanda-joins-nexmos-devrel-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/amanda-joins-nexmos-devrel-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/amanda-joins-nexmos-devrel-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/michael-is-joining-the-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/michael-is-joining-the-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/michael-is-joining-the-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/introducing-nexmo-developer-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/introducing-nexmo-developer-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/introducing-nexmo-developer-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/laracon-2017-nyc-conference-report-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/laracon-2017-nyc-conference-report-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/laracon-2017-nyc-conference-report-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-at-android-summit-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-at-android-summit-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-at-android-summit-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/hello-nexmodev-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/hello-nexmodev-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/hello-nexmodev-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/techcrunch-disrupt-hackathon-focus-rescue",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/techcrunch-disrupt-hackathon-focus-rescue",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/techcrunch-disrupt-hackathon-focus-rescue",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-at-the-swift-summit-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-at-the-swift-summit-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-at-the-swift-summit-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-ssml-support-natural-text-speech",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-ssml-support-natural-text-speech",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-ssml-support-natural-text-speech",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/princeton-coders-lyft-nexmo-apis",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/princeton-coders-lyft-nexmo-apis",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/princeton-coders-lyft-nexmo-apis",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/devrel-week-lisbon-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/devrel-week-lisbon-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/devrel-week-lisbon-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-dotnet-client-library-new-release-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-dotnet-client-library-new-release-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-dotnet-client-library-new-release-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-at-midwest-php-2018-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-at-midwest-php-2018-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-at-midwest-php-2018-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/day-one-nexmo-rwdevcon-2018-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/day-one-nexmo-rwdevcon-2018-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/day-one-nexmo-rwdevcon-2018-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/day-two-recap-nexmo-rwdevcon-2018",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/day-two-recap-nexmo-rwdevcon-2018",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/day-two-recap-nexmo-rwdevcon-2018",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/day-three-nexmo-rwdevcon-2018-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/day-three-nexmo-rwdevcon-2018-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/day-three-nexmo-rwdevcon-2018-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/steve-is-joining-the-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/steve-is-joining-the-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/steve-is-joining-the-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-protects-customers-by-ending-support-for-legacy-tls-protocols",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-protects-customers-by-ending-support-for-legacy-tls-protocols",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-protects-customers-by-ending-support-for-legacy-tls-protocols",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/martyn-davies-joins-nexmo-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/martyn-davies-joins-nexmo-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/martyn-davies-joins-nexmo-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/tony-bedford-nexmo-developer-relations-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/tony-bedford-nexmo-developer-relations-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/tony-bedford-nexmo-developer-relations-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/lorna-joins-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/lorna-joins-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/lorna-joins-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/swipedon-developer-story",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/swipedon-developer-story",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/swipedon-developer-story",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-introduces-in-app-video-developer-preview",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-introduces-in-app-video-developer-preview",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-introduces-in-app-video-developer-preview",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-protects-customers-permanently-disabling-legacy-tls-protocols",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-protects-customers-permanently-disabling-legacy-tls-protocols",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-protects-customers-permanently-disabling-legacy-tls-protocols",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/receive-a-phone-call-with-java-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/receive-a-phone-call-with-java-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/receive-a-phone-call-with-java-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/hui-jing-joins-the-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/hui-jing-joins-the-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/hui-jing-joins-the-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-messages-dispatch-apis-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-messages-dispatch-apis-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-messages-dispatch-apis-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/manik-joins-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/manik-joins-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/manik-joins-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-welcomes-mark-lewin-to-the-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-welcomes-mark-lewin-to-the-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-welcomes-mark-lewin-to-the-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/welcome-ben-greenberg-to-the-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/welcome-ben-greenberg-to-the-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/welcome-ben-greenberg-to-the-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/luke-oliff-joins-the-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/luke-oliff-joins-the-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/luke-oliff-joins-the-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/yuriy-dybskiy-joins-nexmo-dev-rel-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/yuriy-dybskiy-joins-nexmo-dev-rel-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/yuriy-dybskiy-joins-nexmo-dev-rel-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/rails-holiday-vapi-checker-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/rails-holiday-vapi-checker-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/rails-holiday-vapi-checker-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-hackathon-at-php-uk-2019-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-hackathon-at-php-uk-2019-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-hackathon-at-php-uk-2019-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-goes-to-devrelcon-tokyo-2019-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-goes-to-devrelcon-tokyo-2019-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-goes-to-devrelcon-tokyo-2019-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/lauren-lee-joins-the-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/lauren-lee-joins-the-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/lauren-lee-joins-the-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/adam-culp-joins-the-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/adam-culp-joins-the-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/adam-culp-joins-the-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/michael-joins-the-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/michael-joins-the-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/michael-joins-the-nexmo-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/zach-walchuk-joins-the-nexmo-developer-education-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/zach-walchuk-joins-the-nexmo-developer-education-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/zach-walchuk-joins-the-nexmo-developer-education-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-at-birmingham-on-rails-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-at-birmingham-on-rails-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-at-birmingham-on-rails-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/nexmo-ruby-new-release-host-overriding-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/nexmo-ruby-new-release-host-overriding-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/nexmo-ruby-new-release-host-overriding-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/igor-wojda-joins-the-vonage-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/igor-wojda-joins-the-vonage-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/igor-wojda-joins-the-vonage-developer-relations-team-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/developer-challenge-winner-uses-nexmo-number-insight-apis",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/developer-challenge-winner-uses-nexmo-number-insight-apis",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/developer-challenge-winner-uses-nexmo-number-insight-apis",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/welcoming-mark-smith-nexmo-developer-relations-team",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/welcoming-mark-smith-nexmo-developer-relations-team",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/welcoming-mark-smith-nexmo-developer-relations-team",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/ai-bot-use-cases-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/ai-bot-use-cases-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/ai-bot-use-cases-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/announcing-nexmos-net-server-sdk-version-4-3-1-release-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/announcing-nexmos-net-server-sdk-version-4-3-1-release-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/announcing-nexmos-net-server-sdk-version-4-3-1-release-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/how-to-send-an-sms-with-asp-net-core-mvc",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/how-to-send-an-sms-with-asp-net-core-mvc",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/how-to-send-an-sms-with-asp-net-core-mvc",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/5-ways-to-make-http-requests-in-node-js-2020-edition",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/5-ways-to-make-http-requests-in-node-js-2020-edition",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/5-ways-to-make-http-requests-in-node-js-2020-edition",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/anything-to-sms-ifttt-nexmo-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/anything-to-sms-ifttt-nexmo-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/anything-to-sms-ifttt-nexmo-dr",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/lucinda-bone-joins-the-developer-relations-team",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/lucinda-bone-joins-the-developer-relations-team",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/lucinda-bone-joins-the-developer-relations-team",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/video-express-is-here-and-why-it’s-awesome",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/video-express-is-here-and-why-it’s-awesome",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/video-express-is-here-and-why-it’s-awesome",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/simplifying-development-low-code-and-no-code-in-future-applications.",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/simplifying-development-low-code-and-no-code-in-future-applications.",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/simplifying-development-low-code-and-no-code-in-future-applications.",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/vonage-video-api-comes-to-the-vonage-api-dashboard-in-beta",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/vonage-video-api-comes-to-the-vonage-api-dashboard-in-beta",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/vonage-video-api-comes-to-the-vonage-api-dashboard-in-beta",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/payments-over-the-phone-now-supports-multi-currency",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/payments-over-the-phone-now-supports-multi-currency",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/payments-over-the-phone-now-supports-multi-currency",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/publish-from-your-video-elements-with-vonage-video-api",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/publish-from-your-video-elements-with-vonage-video-api",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/publish-from-your-video-elements-with-vonage-video-api",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/introducing-the-meetings-api",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/introducing-the-meetings-api",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/introducing-the-meetings-api",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/announcing-the-video-champions-program",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/announcing-the-video-champions-program",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/announcing-the-video-champions-program",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/meetings-api-is-here",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/meetings-api-is-here",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/meetings-api-is-here",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/video-meeting-scheduling-bot-powered-by-vonage-ai-and-meetings-api",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/video-meeting-scheduling-bot-powered-by-vonage-ai-and-meetings-api",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/video-meeting-scheduling-bot-powered-by-vonage-ai-and-meetings-api",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/announcing-vonage-proactive-connect-open-beta",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/announcing-vonage-proactive-connect-open-beta",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/announcing-vonage-proactive-connect-open-beta",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/blog/getting-started-with-proactive-connect",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/cn/blog/getting-started-with-proactive-connect",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/ja/blog/getting-started-with-proactive-connect",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]