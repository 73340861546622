/**
 * This module bridges the gap in between Nuxt.js capabilities when it comes
 * to dynamic import of runtime variables. Nuxt.js saves the value of the
 * environment variable during build which is not ideal as we want those
 * variables to be populated at runtime.
 *
 * This module replaces the variables with hardcoded strings that can be
 * envsubstituted at runtime in the built file.
 */
import type { RuntimeConfig } from '@nuxt/schema';
import { pascalCase } from 'change-case';
import { IsDevelopment } from '../constants';

export const EnvVars = [
  'BIFROST_ADOBE_DTM_LAUNCH_URL',
  'BIFROST_BUGSNAG_API_KEY',
  'BIFROST_BUGSNAG_APP_ID',
  'BIFROST_CLOUD_RUNTIME_MFE',
  'BIFROST_DASHBOARD_BACKEND_URL',
  'BIFROST_PORTAL_BACKEND',
  'BIFROST_DEV_PORTAL_BACKEND',
  'BIFROST_DEV_PORTAL_FRONTEND',
  'BIFROST_DEV_PORTAL_MFE',
  'BIFROST_ORG_ID',
  'BIFROST_STORYBLOK_ACCESS_TOKEN',
  'BIFROST_STORYBLOK_VERSION',
  'BIFROST_VIAM_TOKEN',
  'BIFROST_VIAM_TOKEN_HEADER',
] as const;

type EnvVarsType = (typeof EnvVars)[number];

/**
 * Get environment variable.
 *
 * If recordAtBuildTime is false the returned value will be setup for using
 * envsubst value for production build.
 *
 * IMPORTANT: Do not use this function outside of the nuxt.config.js
 *            If you need an environment variable outside the config
 *            file simply add it to the runtime config below and the use
 *            `useRuntimeConfig` in your component/file.
 */
export function getEnvironmentVariable(name: EnvVarsType, recordAtBuildTime = false): string {
  return recordAtBuildTime || IsDevelopment ? process.env[name] ?? '' : `$${name}`;
}

/**
 * Add environment variable to runtime config.
 *
 * If recordAtBuildTime is false the returned value will be setup for using
 * envsubst value for production build.
 *
 * IMPORTANT: The added environment variable will be in pascal case.
 *            e.g. BIFROST_DEV_PORTAL_MFE will be useRuntimeConfig().public.BifrostDevPortalMfe
 */
function getRuntimeConfig(name: EnvVarsType, recordAtBuildTime = false): Record<string, string> {
  return {
    [pascalCase(name)]: getEnvironmentVariable(name, recordAtBuildTime),
  };
}

/**
 * Runtime config for Nuxt.js.
 *
 * https://nuxt.com/docs/guide/going-further/runtime-config
 */
const runtimeConfig: Overrideable<RuntimeConfig> = {
  public: {
    // MFE paths are hardcoded for all docker builds
    ...getRuntimeConfig('BIFROST_DEV_PORTAL_MFE', true),
    ...getRuntimeConfig('BIFROST_CLOUD_RUNTIME_MFE', true),
    ...getRuntimeConfig('BIFROST_STORYBLOK_VERSION'),
    ...getRuntimeConfig('BIFROST_STORYBLOK_ACCESS_TOKEN'),
    ...getRuntimeConfig('BIFROST_DEV_PORTAL'),
  },
  ...getRuntimeConfig('BIFROST_DASHBOARD_BACKEND_URL'),
  ...getRuntimeConfig('BIFROST_VIAM_TOKEN_HEADER'),
};

export default runtimeConfig;
