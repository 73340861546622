import { type ProfileInfo } from '@/utils/analytics/ProfileInfo';
import { trackAdobePageView } from '@/utils/analytics/adobe';

const trackPageView = (path: string, profileInfo: ProfileInfo) => {
  trackAdobePageView(path, profileInfo);
};

class Analytics {
  private profileInfo: ProfileInfo | null = null;

  private queuedPageView: string | null = null;

  private lastTrackedPage: string | null = null;

  public setProfileInfo(profileInfo: ProfileInfo) {
    this.profileInfo = profileInfo;
    if (this.queuedPageView) {
      trackPageView(this.queuedPageView, profileInfo);
      this.queuedPageView = null;
    }
  }

  public trackPageView(path: string) {
    // ignore double calls these happen when # jump links are used
    if (path === this.lastTrackedPage) return;

    if (this.profileInfo) {
      this.lastTrackedPage = path;
      trackPageView(path, this.profileInfo);
    } else {
      // If we don't have profile info yet, queue the page view
      this.queuedPageView = path;
    }
  }
}

export default new Analytics();
